

.header-wrapper
    display: flex
    flex-direction: row
    flex-wrap: wrap
    &-img
        width: 100%
        object-fit: cover
        height: auto
    &-svg
        width: 100%
        position: absolute
        top: 0px
        left: 0px
    &-content-title
        position: absolute
        left: 50%
        top: 27%
        color: #fff
        font-size: 4rem
        transform: translate(-50%,-50%)
        &-sub
            position: absolute
            color: #fff
            font-size: 1.5rem
            left: 50%
            top: 35%
            transform: translate(-50%,-50%)

.about-content-wrapper
    display: flex
    flex-basis: 100%
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
    &-content 
        display: flex
        background-color: #fff
        flex-basis: 60%
        @media screen and (max-width: 1200px)
            flex-basis: 100%



.mobile
    &-header-wrapper
        margin-top: 150px
        display: flex
        flex-basis: 100%
        flex-direction: row
        flex-wrap: wrap
        justify-content: center
        &-content 
            display: flex
            flex-basis: 90%
            flex-direction: row
            flex-wrap: wrap
            color: #fff
            &-background
                position: absolute
                top: 0px
                left: 0px
                width: 100%
                height: 450px
                z-index: -999
                background: linear-gradient(233deg, rgb(90, 166, 254) 40%, rgba(26, 154, 226, 0.58) 60%)

            h1
                flex-basis: 100%
                font-size: 3.5rem
                text-align: center
                margin-bottom: 0
            p
                flex-basis: 100%
                font-size: 2rem
                text-align: center
                margin-bottom: 50px
    &-content-wrapper-content
        display: flex
        background-color: #fff
        flex-basis: 50%
        height: auto
        background: #FFFFFF
        border-radius: 30px 
        padding: 15px 15px 15px 15px
        z-index: 999
        margin-top: -25px
        margin-bottom: 50px
        box-shadow: 0px 0px 20px 3px rgba(1,1,1,0.2)
        @media screen and (max-width: 1200px)
            flex-basis: 100%
