@import '../../global-styles/_variables.sass'

$width-footer: 500px


.background-promotion
    position: fixed !important
    height: 100vh !important
    width: 100vw !important
    background: rgba(0, 0, 0, 0.3)
    z-index: 1009 !important
.promotion
    &-container 
        height: 700px
        width: 70%
        z-index: 10000
        background: #ffffff 
        display: flex !important
        flex-direction: column 
        flex-wrap: wrap
        position: fixed !important
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        max-width: 1900px


    &-title
        position: relative
        h2
            margin-top: 70px
            font-size: 32px
            font-weight: bold
            font-family: "Poppins", sans-serif
            color: #fff
            text-align: center
        p
            position: absolute
            right: 50px
            top: -10px
            font-size: 32px
            color: #FFFFFF
            cursor: pointer
            svg
                margin-top: 0px
                transform: translate(0px, 5px)
                margin-right: 10px
                cursor: pointer
            #text
                margin-top: 10px
                cursor: pointer
    &-ofert
        margin-top: 80px
        color: #FFFFFF
        text-align: center
        font-size: 22px
        
    &-photo
        width: 100%
        height: 100%
        position: absolute
        z-index: -1
        background-position: center center

    &-footer
        display: flex
        flex-direction: row
        align-content: center
        width: $width-footer
        justify-content: space-evenly
        margin-top: 15vh
        align-self: center
    &-check
        flex-basis: 40%
        text-align: center
        background: $cromed_dark_blue
        color: #FFFFFF
        height: 50px
        vertical-align: center
        line-height: 50px
        font-size: 1.1rem
        border-radius: 15px

    &-quit
        flex-basis: 40%
        text-align: center
        background: #FFFFFF
        height: 50px
        line-height: 50px
        font-size: 1.1rem
        border-radius: 15px










.card-list-svg-promotion
    width: 22px
    height: 22px
    padding-top: 8px







