#privacy-header
    text-align: center

.privacy
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
.privacy-content
    width: 60%
    display: flex
    flex-direction: column
    justify-content: center
    align-content: center
    > h2
        text-align: center
        margin-top: 80px
    > p 
        text-align: left