.mobile-poz-content
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center
    > ol, ul
        li
            text-align: left
            > .link
                color: #24679a
                font-weight: 700
                font-size: 1.1rem
                cursor: pointer
    a
        color: #24679a
        font-weight: 700
        font-size: 1.1rem
    h2
        margin-top: 80px

#lab
    font-size: 2.3rem
    margin-top: 50px
    &_p
        font-size: 1.27rem
