.home-mobile-wrapper
    width: 100%
    background: #fff
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center
    padding-bottom: 150px
    .home-mobile-center
        height: 500px
        width: 60%
        text-align: center
        display: flex
        justify-content: center
        align-items: center
        flex-wrap: wrap
        margin-top: 50px
        .home-mobile-center-header
            width: 100%
            display: flex
            justify-content: center
            align-items: center
            flex-wrap: wrap
            margin-top: 50px
            h1
                color: #1B415F
                width: 100%
                display: flex
                justify-content: center
                align-items: center
                flex-wrap: wrap
            :nth-child(1)
                font-size: 1.9rem
                margin-bottom: 10px
                font-weight: 300
            :nth-child(2)
                font-size: 2.3rem
                font-weight: 600
        .home-mobile-center-logo-wrapper
            width: 100%
            margin: 30px 0 
            display: flex
            justify-content: center
            align-items: center
            flex-wrap: wrap
            img
                height: 100px
                width: auto
        .home-mobile-center-button-wrapper
            width: 100%
            display: flex
            justify-content: center
            align-items: center
            flex-wrap: wrap
            z-index: 999
            button
                padding: 15px 60px
                border: none
                background: rgb(221, 46, 68 / 0.88)
                box-shadow: 0px 0px 21px rgba(54, 54, 54, 0.19)
                color: #fff
                border-radius: 30px
                font-size: .9rem
                font-weight: 500
                letter-spacing: .235em
    .home-mobile-svg-wrapper
        position: absolute
        left: 0
        bottom: -25%
        z-index: 1
    .home-mobile-bottom-wrapper
        width: 100%
        height: auto
        margin-top: 170px
        z-index: 2
        .home-mobile-bottom
            margin: 10px
            height: 90%
            display: flex
            justify-content: center
            align-items: center
            flex-wrap: wrap
            .home-mobile-bottom-header
                text-align: right
                height: 30px
                width: 100%
                span
                    font-size: 1.1rem
                    font-weight: 300
                    margin-right: 60px
                    position: relative
                    &::after
                        content: '>'
                        position: absolute
                        right: -25px
                        font-size: 1.5rem
                        top: 0
                        transform: translateY(-15%)
                        color: #5AA6FE
            .home-mobile-bottom-container
                width: 100%
                height: calc(100% - 60px)
                margin-top: 30px
                display: flex
                align-items: flex-start
                justify-content: center
                flex-wrap: wrap
                .home-mobile-bottom-big-card
                    position: relative
                    &::after
                        content: '>'
                        position: absolute
                        right: 20px
                        bottom: -50px
                        font-size: 2rem
                        color: #DD2E44
                        
                    
                .home-mobile-bottom-card-wrapper
                    background: #fff
                    box-shadow: 0px 0px 40px rgba(24, 48, 66, 0.08)
                    border-radius: 21px
                    display: flex
                    justify-content: center
                    align-items: center
                    position: relative
                    margin: 10px
                    .home-mobile-bottom-card-center 
                        display: flex
                        flex-wrap: wrap
                        width: 100%
                        text-align: center
                        :nth-child(1)
                            width: 100%
                            font-size: 1rem
                        :nth-child(2)
                            width: 100%
                            font-size: 1.5rem
                            font-weight: 600
                            margin-top: 5px
                        .home-mobile-bottom-card-dots
                            position: absolute
                            bottom: 5px
                            right: 15px
                            svg
                                width: auto
                                height: 25px
.cards-wrapper-mobile
    width: 97%
    height: auto
    margin: 0 auto
    overflow: hidden
    .cards-header-background
        background: #5AA6FE
        border-radius: 28px 28px 0 0
        position: relative
        width: 100%
        height: 150px
        span
            width: 100%
            height: 100px
            margin-top: 200px
            margin-left: 50px
            color: #fff
            font-size: 2.7rem
            font-weight: bold
        .cards-header
            background: #fff
            width: 80%
            min-height: 125px
            position: absolute
            bottom: -50px
            left: 50%
            transform: translateX(-50%)
            box-shadow: 0px 4px 28px rgba(49, 76, 101, 0.28)
            border-radius: 15px
            &::after
                content: ''
                position: absolute
                bottom: 20px
                right: 20px
                width: 50px
                height: 5px
                background: #1B415F
            &-title
                width: 100%
                font-size: 1.4rem
                margin: 15px
                font-weight: 500
                color: #1B415F
            &-desc
                width: 100%
                font-size: .9rem
                margin: 15px 15px
                color: #1B415F
    .cards-container
        width: 100%
        height: auto
        margin-top: 100px
        .card
            width: 80%
            height: 300px
            margin: auto
            background: #FFFFFF
            box-shadow: 0px 0px 83px rgba(19, 56, 84, 0.16)
            border-radius:  15px 15px 0 0
            margin-bottom: -50px
            justify-content: flex-start
            position: relative
            &::after
                content: ''
                position: absolute
                bottom: 100px
                right: 50px
                width: 40px 
                height: 5px
                background: #E87382
            &-svg
                width: 100%
                height: 50px
                position: relative
                margin-top: 50px
                margin-left: calc(50% - 230px)
                svg
                    position: absolute
                    left: 0               
            &-description
                margin-left: 30px
                width: 100%
                
.methods-wrapper-mobile
    width: 97%
    height: auto
    margin: 0px auto
    overflow: hidden
    .cards-header-background
        background: #DD2E44
        border-radius: 28px 28px 0 0
        position: relative
        width: 100%
        height: 150px
        span
            width: 100%
            height: 100px
            margin-top: 200px
            margin-left: 50px
            color: #fff
            font-size: 2.7rem
            font-weight: bold
        .cards-header
            background: #fff
            width: 80%
            min-height: 125px
            position: absolute
            bottom: -50px
            left: 50%
            transform: translateX(-50%)
            box-shadow: 0px 4px 28px rgba(49, 76, 101, 0.28)
            border-radius: 15px
            &::after
                content: ''
                position: absolute
                bottom: 20px
                right: 20px
                width: 50px
                height: 5px
                background: #1B415F
            &-title
                width: 100%
                font-size: 1.4rem
                margin: 15px
                font-weight: 500
                color: #1B415F
            &-desc
                width: 100%
                font-size: .8rem
                margin: 15px 15px
                color: #1B415F
    .methods-container
        width: 100%
        height: auto
        margin-top: 100px
        .methods-list-ul
            width: 90%
                

