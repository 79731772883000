@import '../../../global-styles/_variables' 


    
.login
    &-container
        display: flex
        flex-direction: row
        width: 100%
        height: 100vh
        justify-content: center
        justify-items: center
        align-items: center
        align-self: center
        > .row
            display: flex
            flex-direction: column
            width: 50vh
            height: 40vh
            background: $cromed_dark_blue
            border-radius: 5px
            align-items: center
            > div 
                display: flex
                flex-direction: column
                align-items: center
                position: relative
                > img 
                    width: 20%
                > h3
                    position: absolute
                    font-weight: 400
                    font-size: 20px 
                    text-align: center
                    font-family: Poppins
                    top: 60%
                    color: #ffffff !important    
                
            > form
                    display: flex
                    flex-direction: column
                    width: 80%
                    align-items: center
                    color: #ffffff !important
                    margin-top: 20px
                    > div 
                        display: flex
                        flex-direction: column 
                        width: 80%
                        color: #ffffff !important
                        align-items: center
                        > label
                            font-size: 20px
                            text-align: center
                            margin-bottom: 10px
                        > input
                            border-radius: 5px
                            outline: none
                            border: none
                            height: 30px
                            margin-bottom: 10px
                            width: 100%
                        > button
                            border: none
                            height: 40px
                            border-radius: 5px
                            background: #ededed
                            margin-top: 10px
                            font-size: 17px
                            width: 150px
                    // 1

@include phone 
    .login
        color: #ffffff !important
        &-container
            > .row
                width: 100% 
                height: 100vh
                border-radius: 0px
                // justify-content: center
                
                align-items: center
                div 
                    display: flex
                    flex-direction: column
                    align-items: center
                    position: relative
                    > img 
                        width: 80%
                    > h3
                        position: absolute
                        font-weight: 400
                        font-size: 20px 
                        text-align: center
                        font-family: Poppins
                        top: 60%
                        color: #ffffff !important    
                
                > form
                    display: flex
                    flex-direction: column
                    width: 80%
                    align-items: center
                    color: #ffffff !important
                    > div 
                        display: flex
                        flex-direction: column 
                        width: 80%
                        color: #ffffff !important
                        > label
                            font-size: 20px
                            text-align: center
                            margin-bottom: 10px
                        > input
                            border-radius: 5px
                            outline: none
                            border: none
                            height: 30px
                            margin-bottom: 10px
                            width: 100%
                        > button
                            border: none
                            height: 40px
                            border-radius: 5px
                            background: #ededed
                            margin-top: 10px
                            font-size: 17px
                            width: 150px
                    // 1