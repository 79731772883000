.content-wrapper
    display: flex
    flex-basis: 100%
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
    &-content 
        display: flex
        background-color: #fff
        flex-basis: 50%
        height: auto
        background: #FFFFFF
        border-radius: 30px 
        padding: 15px 15px 15px 35px
        z-index: 999
        margin-top: -100px
        margin-bottom: 50px
        flex-wrap: wrap
        box-shadow: 0px 0px 20px 3px rgba(1,1,1,0.2)
        @media screen and (max-width: 1200px)
            flex-basis: 100%
            margin-top: -20px

.content
    &-info 
        &-box
            display: flex
            flex-basis: 50%
            height: fit-content
            flex-direction: row
            flex-wrap: wrap
            justify-items: flex-start
            justify-content: center
            min-width: 300px
            @media screen and (max-width: 1200px)
                flex-basis: 100%
        &-h4
            flex-basis: 100%
            font-size: 1.3rem
            color:#000
        &-pos 
            display: flex
            flex-basis: 100%
            flex-direction: row
            flex-wrap: wrap
            margin-bottom: 20px
            margin-left: 15px
            p
                margin: 0
                margin-left: 10px

        // #818181

.info
    &-input
        &-box
            justify-items: flex-start
            flex-basis: 100%
            height: auto
            display: flex
            flex-direction: row
            flex-wrap: wrap
            height: fit-content
            padding-top: 5px
        &-label
            font-size: 1.3rem
            flex-basis: 100%
            height: fit-content
        height: 50px
        flex-basis: 100%
        border-radius: 30px
        border: none
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2)
        -webkit-box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2)
        padding-left: 10px 
        &-textarea
            height: 95px
            flex-basis: 100%
            border-radius: 30px
            border: none
            box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2)
            -webkit-box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2)
            resize: none
            min-height: 100px
            max-height: 140px
            padding-left: 10px 
            padding-top: 10px
            &:focus
                outline: none
        &-btn
            width: 60%
            background: linear-gradient(89.81deg, rgba(36, 57, 89, 0.92) 5.91%, rgba(26, 154, 226, 0.58) 87.73%)
            color: #FFFFFF
            border-radius: 30px
            text-align: center
            font-size: 1.5rem
            margin-top: 20px
            p
                margin: 0px
                padding: 10px 0px 10px 0px
        &:focus
            outline: none
.mobile
    &-header-wrapper
        margin-top: 80px
        display: flex
        flex-basis: 100%
        flex-direction: row
        flex-wrap: wrap
        justify-content: center
        &-content 
            display: flex
            flex-basis: 90%
            flex-direction: row
            flex-wrap: wrap
            color: #fff
            &-background
                position: absolute
                top: 0px
                left: 0px
                width: 100%
                height: 450px
                z-index: -999
                background: linear-gradient(233deg, rgb(90, 166, 254) 40%, rgba(26, 154, 226, 0.58) 60%)

            h1
                flex-basis: 100%
                font-size: 3.5rem
                text-align: center
                margin-bottom: 0
            p
                flex-basis: 100%
                font-size: 2rem
                text-align: center
                margin-bottom: 50px
            #offert
                flex-basis: 100%
                font-size: 1.4rem
                text-align: center
                margin-bottom: 50px


.header-wrapper
    display: flex
    flex-direction: row
    flex-wrap: wrap
    height: 540px
    position: relative
    &-img
        width: 100%
        object-fit: cover
        height: auto
    &-svg
        width: 100%
        position: absolute
        top: 0px
        left: 0px
    &-content-title
        position: absolute
        left: 50%
        top: 50%
        color: #fff
        font-size: 4rem
        transform: translate(-50%,-50%)

        &-sub
            position: absolute
            color: #fff
            font-size: 1.8rem
            left: 50%
            top: 65%
            transform: translate(-50%,-50%)
