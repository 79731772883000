$cromed_blue: #5AA6FE
$cromed_dark_blue: #3B88C3
$cromed_red: #DD2E44 

$phone: 640px 
$tablet: 768px
$desktop: 1024px


@mixin phone()
    @media (max-width: #{$tablet - 1px})
        @content


@mixin tablet()
    @media (min-width: #{$tablet}) and (max-width: #{$desktop - 1px})
        @content

@mixin desktop()
    @media (min-width: #{$desktop})
        @content
    