
$blue: #5AA6FE    
    
.main-container
    display: flex
    flex-direction: row
    flex-wrap: wrap
    margin: 0
    padding: 0
    
.darken
    z-index: 0
    background-color: rgba(1,1,1,0.35)
    width: 100%
    height: 100%
    position: absolute
    left: 0
    top: 0

.btn-blue
    width: fit-content
    height: fit-content
    padding: 10px 15px 10px 15px
    border-radius: 8px
    background: $blue
    color: #fff
    z-index: 2
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25)
    &-dark
        color: rgba(255,255,255,1) 
    p
        margin: 0
.wide
    padding: 10px 35px 10px 35px
.alfred
    &-row
        position: relative
        height: 500px
        display: flex
        width: 100%
        margin-top: 40px
        flex-direction: column
        flex-wrap: wrap
        padding: 20px 20px 5px 30px
        background-blend-mode: darken
        // z-index: -1
        justify-content: left
        align-content: flex-start
        h1
            font-family: 'Poppins'
            font-weight: 800
            font-size: 37px
            width: 95%
            color: #fff
            z-index: 2
            margin-bottom: 3px
        >p
            font-family: 'Poppins'
            font-weight: 400
            font-size: 17px
            width: 80%
            color: #fff
            z-index: 3
        >ul 
            list-type:none
        > .btn-blue
            > a
                color: #fff
.benjamin
    &-row
        position: relative
        height: 270px
        width: 100%
        display: flex
        flex-direction: column
        // flex-wrap: wrap
        justify-content: center
        align-content: flex-start
        align-items: center
        padding: 40px 0px
        h2
            color: #000
            font-family: 'Poppins'
            font-weight: 500
            text-align: center
            font-size: 26px
            margin-bottom: 0px
        >p
            font-family: Poppins
            font-style: normal
            font-weight: normal
            font-size: 16px
            line-height: 24px
            width: 60%
            text-align: center
            margin-top: 10px
    &-row-blue
        position: relative
        height: 270px
        width: 100%
        display: flex
        flex-direction: column
        // flex-wrap: wrap
        justify-content: center
        align-content: flex-start
        align-items: center
        padding: 40px 0px
        background: #24679a
        h2
            color: #fff
            font-family: 'Poppins'
            font-weight: 500
            text-align: center
            font-size: 26px
            margin-bottom: 0px
        >p
            font-family: Poppins
            font-style: normal
            font-weight: normal
            font-size: 16px
            line-height: 24px
            width: 60%
            text-align: center
            margin-top: 10px
            color: #fff
        > .btn-blue 
            background: #fff
            color: #24679a

.charlie
    &-row
        position: relative
        height: 333px
        display: flex
        width: 100%
        // margin-top: 80px
        flex-direction: row
        flex-wrap: wrap
        padding: 20px 20px 5px 30px
        background-blend-mode: darken
        z-index: -1
        justify-content: left
        align-items: center
        align-content: flex-end
        >h1
            font-family: Poppins
            font-style: normal
            font-weight: bold
            font-size: 54px
            line-height: 81px
            color: #fff
            z-index: 2
            margin: 0
        >p
            font-family: Poppins
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 24px
            color: #fff
            z-index: 3
            margin-top: 0
            margin-bottom: 25px
        .new
            position: absolute
            left: 0
            top: 20px
            width: 100px
            height: 30px
            z-index: 222
            background-color: #fff
            >p 
                font-family: Poppins
                font-style: normal
                font-weight: bold
                font-size: 17px
                line-height: 30px
                margin: 0
                display: flex  
                justify-content: center
                justify-items: center
                align-items: center
                color: $blue
    &-sister
        &-row
            position: relative
            display: flex
            flex-direction: column
            flex-wrap: wrap
            justify-content: center
            justify-items: center
            align-items:  center
            align-content: center
            width: 100%
            padding: 5px 20px 5px 20px
            >h2
                font-family: Poppins
                font-style: normal
                font-weight: 500
                font-size: 21px
                line-height: 27px
                text-align: center
            p
                font-family: Poppins
                font-style: normal
                font-weight: 300
                font-size: 14px
                line-height: 18px
                display: flex
                align-items: center
                text-align: center
            table
                border-collapse: collapse
                width: 100%
            table, td, th 
                border: 1px solid
                text-align: center

.dwayne
    &-row
        position: relative
        height: 333px
        display: flex
        width: 100%
        // margin-top: 80px
        flex-direction: row
        flex-wrap: wrap
        padding: 20px 20px 5px 30px
        background-blend-mode: darken
        z-index: -1
        justify-content: left
        align-items: center
        align-content: flex-end
        margin-top: 20px
        >h1
            font-family: Poppins
            font-style: normal
            font-weight: bold
            font-size: 41px
            line-height: 40px
            color: #fff
            z-index: 2
            margin: 0
        >p
            font-family: Poppins
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 24px
            color: #fff
            z-index: 3
            margin-top: 0
            margin-bottom: 25px
        .new
            position: absolute
            left: 0
            top: 20px
            width: 150px
            height: 30px
            z-index: 222
            background-color: #fff
            >p 
                font-family: Poppins
                font-style: normal
                font-weight: bold
                font-size: 17px
                line-height: 30px
                margin: 0
                display: flex  
                justify-content: center
                justify-items: center
                align-items: center
                color: $blue
    &-sister
        &-row
            display: flex
            width: 100%
            height: 200px
            flex-direction: column
            justify-content: center
            justify-items: center
            align-items: center
            align-content: center
            position: relative
            >h2
                font-family: Poppins
                font-style: normal
                font-weight: 500
                font-size: 26px
                line-height: 27px
                text-align: center
            p
                font-family: Poppins
                font-style: normal
                font-weight: 300
                font-size: 14px
                line-height: 18px
                display: flex
                align-items: center
                text-align: center
                width: 70%

.elon
    &-row 
        position: relative
        height: 333px
        display: flex
        width: 100%
        // margin-top: 80px
        flex-direction: row
        flex-wrap: wrap
        padding: 20px 20px 5px 30px
        background-blend-mode: darken
        z-index: -1
        justify-content: left
        align-items: center
        align-content: flex-end
        margin-top: 20px
        >h1
            font-family: Poppins
            font-style: normal
            font-weight: bold
            font-size: 41px
            line-height: 42px
            color: #fff
            z-index: 2
            margin: 0
        >p
            font-family: Poppins
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 24px
            color: #fff
            z-index: 3
            margin-top: 5px
            margin-bottom: 25px
        .new
            position: absolute
            left: 0
            top: 20px
            width: 150px
            height: 30px
            z-index: 222
            background-color: #fff
            >p 
                font-family: Poppins
                font-style: normal
                font-weight: bold
                font-size: 17px
                line-height: 30px
                margin: 0
                display: flex  
                justify-content: center
                justify-items: center
                align-items: center
                color: $blue
    &-sister 
        &-row
            display: flex
            width: 100%
            height: 250px
            flex-direction: column
            justify-content: center
            justify-items: center
            align-items: center
            align-content: center
            position: relative
            >h2
                font-family: Poppins
                font-style: normal
                font-weight: 500
                font-size: 26px
                line-height: 27px
                text-align: center
            .socials
                display: flex
                flex-direction: row
                flex-wrap: nowrap
                justify-content: space-evenly
                justify-items: center
                align-content: center
                align-items: center
                width: 50%
                margin-bottom: 30px

.xander
    &-row 
        position: relative
        height: 333px
        display: flex
        width: 100%
        // margin-top: 80px
        flex-direction: row
        flex-wrap: wrap
        padding: 20px 20px 5px 30px
        background-blend-mode: darken
        z-index: -1
        justify-content: left
        align-items: center
        align-content: flex-end
        margin-top: 20px
        >h1
            font-family: Poppins
            font-style: normal
            font-weight: bold
            font-size: 41px
            line-height: 42px
            color: #fff
            z-index: 2
            margin: 0
        >p
            font-family: Poppins
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 24px
            color: #fff
            z-index: 3
            margin-top: 5px
            margin-bottom: 25px
        .new
            position: absolute
            left: 0
            top: 20px
            width: 150px
            height: 30px
            z-index: 222
            background-color: #fff
            >p 
                font-family: Poppins
                font-style: normal
                font-weight: bold
                font-size: 17px
                line-height: 30px
                margin: 0
                display: flex  
                justify-content: center
                justify-items: center
                align-items: center
                color: $blue
    &-sister 
        &-row
            display: flex
            width: 100%
            height: 600px
            flex-direction: column
            justify-content: center
            justify-items: center
            align-items: center
            align-content: center
            position: relative
            >h2
                font-family: Poppins
                font-style: normal
                font-weight: 500
                font-size: 26px
                line-height: 27px
                text-align: center
            .socials
                display: flex
                flex-direction: row
                flex-wrap: nowrap
                justify-content: space-evenly
                justify-items: center
                align-content: center
                align-items: center
                width: 50%
                margin-bottom: 30px
            >.list
                display: flex
                flex-direction: row
                width: 85%
                padding: 0px 10px 0px 10px
                margin-left: 20px
                >span
                    font-family: Poppins
                    font-style: normal
                    font-weight: bold
                    font-size: 24px
                    line-height: 26px
                    display: flex
                    align-items: center
                    margin-right: 10px
                    color: #5AA6FE
                >p
                    margin: 5px 0px 5px 0px