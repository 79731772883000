.faq-wrapper
    width: 100%
    height: auto
    .faq-header-wrapper
        width: 100%
        height: 200px
        display: flex 
        flex-wrap: wrap
        justify-content: center
        align-items: center
        text-align: center
        .faq-header
            .faq-header-top
                font-size: 2.2rem
                position: relative
                width: auto
                color: #353535
                &::after
                    content: ''
                    display: inline-block
                    position: absolute
                    bottom: -5px
                    left: 50%
                    transform: translateX(-50%)
                    width: 10%
                    height: 2px
                    background: #E66D76
            .faq-header-bottom
                color: #000
                font-weight: 300
                width: 60%
                margin: 20px auto 0 auto
    .faq-questions-wrapper 
        height: auto
        width: 100%
        .faq-questions
            margin: 50px 
            @media screen and (max-width: 1200px) 
                margin: 0px
            .faq-question-wrapper
                width: 100%
                background: #FFFFFF
                box-shadow: 0px 0px 71px rgba(0, 0, 0, 0.08)
                border-radius: 17px
                height: 100px
                overflow: hidden
                margin: 20px 0
                transition: .2s all linear !important
                cursor: pointer
                .faq-question
                    margin: 0px 50px
                    .faq-question-header
                        width: 100%
                        height: 100px
                        display: flex
                        flex-wrap: wrap
                        align-items: center
                        position: relative
                        .faq-question-header-text
                            display: flex
                            flex-wrap: wrap
                            align-items: center
                            width: 100%
                            height: 100%
                            color: rgba(42, 63, 94, 0.71)
                            transition: .1s all linear
                        .faq-question-header-arrow
                            p
                                position: absolute
                                top: 0
                                transform: translateY(-50%)
                                text-align: center
                                right: 10px
                                font-size: 2.3rem
                                width: auto
                                height: auto
                    .faq-question-content
                        padding: 20px 0 40px 0
                        color: rgba(42, 63, 94, 0.71)
                        p
                            color: rgba(42, 63, 94, 0.71)
                            margin-top: 30px
                            margin-bottom: 80px