@import '../../global-styles/_variables.sass'

.menu-wrapper
    width: 100%
    height: 100px
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    align-items: center
    background: #fff
    border-bottom: 1px solid #eee
    position: fixed
    top: 0
    left: 0
    z-index: 1000
    .menu-logo-wrapper
        width: 20%
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        z-index: 1200
        .img-wrapper
            display: flex
            align-items: center
            justify-content: center
            z-index: 1200
            img
                width: 50%
                height: auto
                cursor: pointer
                @media screen and (max-width: 1200px)
                    width: 70%
    .menu-categories-wrapper
        width: 50%
        height: 100%
        display: flex
        flex-wrap: wrap
        @media screen and (max-width: 1500px)
            width: 60%
        @media screen and (max-width: 1000px)
            width: 70%
        @media screen and (max-width: 900px)
            width: 77%
        @media screen and (max-width: 768px)
            justify-content: flex-end
            align-items: center
            position: relative
        .menu-categories
            width: 70%
            height: 100%
            color: #585858
            display: flex
            justify-content: space-between
            align-items: center
            @media screen and (max-width: 768px)
                display: none
            .menu-category
                font-weight: 600
                font-size: 1.1rem
                position: relative
                cursor: pointer
                transition: .2s all ease-in-out
                height: 100%
                display: grid
                place-items: center
                p
                    margin: 0

                svg 
                    width: 15px
                    height: 10px

                .menu-link-dropdown
                    border-radius: 10px
                    z-index: 99
                    opacity: 0
                    position: absolute
                    background: rgba(255, 255, 255, 1)
                    border: 1px solid #eee
                    min-width: 200px
                    height: auto
                    transition: .2s bottom opacity linear
                    -webkit-transition: .2s all linear
                    -moz-transition: .2s all linear
                    -ms-transition: .2s all linear
                    -o-transition: .2s all linear
                    pointer-events: none
                    text-align: left
                    list-style-type: none
                    font-size: 16px
                    top: 70%
                    @media screen and (max-width: 1500px)
                        font-size: 12px
                    @media(max-width: 1200px)
                        display: none
                    .menu-link-dropdown-content
                        @media(max-width: 1200px)
                            display: none
                        .menu-link-dropdown-content-item
                            margin: 3%
                            padding: 2%
                            border-radius: 10px
                            transition: 0.2s all linear
                            @media(max-width: 1200px)
                                display: none
                                width: 100%
                                height: 35px
                                line-height: 35px
                            &:hover
                                background: $cromed_blue
                                @media(max-width: 1200px)
                                    display: none
                            &:hover a
                                color: #fff
                                @media(max-width: 1200px)
                                    display: none
                            p
                                margin: 0
                                text-align: center
                                font-size: 14px
                                @media(max-width: 1200px)
                                    display: none
                                    margin-left: 15px
                                    font-weight: 600
                &:hover .menu-link-dropdown
                    pointer-events: all
                    // display: block
                    opacity: 1
                    top: 75%
                &:active .menu-link-dropdown-content-item
                    filter: brightness(90%)

                @media(max-width: 1200px)
                    display: none
                &:not(:nth-child(1), :nth-child(2))
                    transition: .5s all
                    position: relative
                    &:hover
                        &::after
                            width: 120%
                        &::before
                            left: 95%
                            opacity: 1
                        filter: brightness(200%)
                    // &::after
                    //     content: ''
                    //     width: 0
                    //     transition: .2s all
                    //     height: 100%
                    //     border-bottom: 2px solid $cromed_blue
                    //     position: absolute
                    //     bottom: 20%
                    //     left: -10%
                    //     overflow: visible
                    //     pointer-events: none
                    //     user-select: none
                    // &::before
                    //     content: url('data:image/svg+xml,%3Csvg width="102" height="auto" viewBox="333 0 422 82" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M382.946 11.3831C381.604 13.5002 379.222 14.9091 376.5 14.9091C372.314 14.9091 368.917 11.5695 368.917 7.45455C368.917 3.33964 372.314 0 376.5 0C379.378 0 381.884 1.58036 383.17 3.90245C392.99 5.002 399.8 10.5594 399.22 19.7024C398.518 30.3586 386.73 48.1005 376.466 55.8196C376.098 59.819 373.778 64.1389 369.664 66.6585C373.429 72.6557 379.279 75.1754 385.657 74.4374C391.834 73.7255 397.578 69.3459 400.672 64.3998C397.502 62.0106 395.458 58.261 395.458 54.0455C395.458 46.8444 401.404 40.9963 408.729 40.9963C416.051 40.9963 422 46.8444 422 54.0455C422 61.2615 415.945 67.4674 407.865 67.0574C403.485 75.0039 394.211 82 383.78 82C373.493 82 365.614 76.4203 361.333 67.0872C357.341 64.7353 354.398 59.8675 353.807 55.7973C343.285 47.8284 331 29.069 331 18.6625C331 9.78409 338.109 4.93864 347.077 3.91364C348.358 1.58409 350.868 0 353.75 0C357.936 0 361.333 3.33964 361.333 7.45455C361.333 11.5695 357.936 14.9091 353.75 14.9091C351.039 14.9091 348.658 13.5076 347.319 11.4017C339.546 12.5162 337.643 16.1987 339.016 21.5846C341.416 31.1525 352.685 47.1835 361.417 51.8538C364.287 53.4044 365.94 53.423 368.848 51.8613C379.185 46.3337 391.526 26.8997 391.667 18.8451C391.75 14.0444 387.765 12.0577 382.946 11.3831V11.3831ZM408.729 59.6364C405.59 59.6364 403.042 57.1242 403.042 54.0455C403.042 50.9593 405.59 48.4545 408.729 48.4545C411.869 48.4545 414.417 50.9593 414.417 54.0455C414.417 57.1242 411.869 59.6364 408.729 59.6364Z" fill="%235AA6FE"%3E%3C/path%3E%3C/svg%3E')
                    //     position: absolute
                    //     background: white
                    //     width: 100%
                    //     bottom: 0
                    //     left: -40%
                    //     transition: .2s all
                    //     overflow: hidden
                    //     opacity: 0
                    //     z-index: 99
                    //     user-select: none
                    //     pointer-events: none
    .menu-flags-wrapper
        width: 30%
        height: 100%
        display: flex
        justify-content: center
        align-items: center
        @media screen and (max-width: 768px)
            display: none
        .menu-flags
            display: flex
            justify-content: center
            align-items: center
            .menu-flag
                width: 30px
                height: auto
                margin: 0 5px
                display: flex
                justify-content: center
                align-items: center
                img
                    width: 100%
                    height: auto
                    border: 1px solid #000
@media screen and (max-width: 1200px)
    .menu-mobile-wrapper
        z-index: 1000
        width: 100%
        height: 80px
        position: fixed
        top: 0px
        right: 0px
        .menu-mobile-content
            width: 100%
            height: 100%
            position: relative
            background: #fff
            float: right
            .menu-hamburger-wrapper
                position: absolute
                z-index: 9999999
                right: 25px
                top: 40%
                transform: translateY(-50%)
            .menu-mobile-content
                width: 100%
                height: 100vh
                background: #ffffff
                color: #000
                display: flex
                align-items: flex-start
                justify-content: center
                flex-wrap: wrap
                transform: translateX(300%)
                div
                    width: 100%
                    height: auto
                    display: flex
                    flex-direction: column
                    align-items: flex-start
                    justify-content: center
                    align-content: center
                    flex-wrap: wrap
                    margin-top: 80px
                    img
                        width: 80%
                    a
                        width: auto
                        text-align: left
                        padding-left: 10px
                        p
                            width: 100%
                            padding: 0px
                            color: #24679a
                            font-size: 1.5rem
                            font-weight: 500
                            // background: rgba(255, 255, 255, 0.4)
                            border-radius: 13px
                            margin: 10px 0 10px 0
            .menu-mobile-svg-wrapper
                position: absolute
                top: 0
                z-index: 999999
                right: 0
                svg
                    width: auto
                    height: 200px

.menu-mobile-logo
    margin-left: 20px

a
    text-decoration: none
    color: #585858
