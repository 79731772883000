@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')

svg
    overflow: visible

.mobile
    &-container
        position: relative
        display: flex
        flex-direction: row
        flex-wrap: wrap
        width: 100vw
        justify-content: center
    &-header
        display: flex
        flex-direction: row
        position: relative
        &-wrapper
            margin-bottom: 30px
    &-table
        width: 100%
        min-height: 200px
        border-radius: 17px
        th
            color: #111
            font-weight: 400
            height: 50px
            text-align: left

            padding: 8px
        td
            // border: 1px solid #ddd
            border-right: 1px solid #ddd
            padding: 8px
            @media screen and (max-width: 1200px)
                border-bottom: 1px solid #eee
                font-size: .9rem
                letter-spacing: .055em
                color: #333
        tr
            border-bottom: 1px solid #ddd
            width: 100%
        td:nth-child(2)
            border-right: none
            width: 25%
            // background-color: #f2f2f2      

        &-wrapper
            width: 100%
            display: flex
            height: 80px
            background: linear-gradient(92.1deg, #243959 -40.26%, #1A9AE2 97.43%)
            flex-direction: row
            flex-wrap: wrap
            padding: 0px 10px 0px 10px
            p
                font-family: Poppins
                font-style: normal
                font-weight: 600
                font-size: 20px
                line-height: 45px
                letter-spacing: 0.055em
                color: #fff
                padding-left: 10px
                font-size: 1.2rem
        &-label
            width: 100%
            h2
                font-family: Poppins
                height: 160px
                font-size: 2.5rem
                line-height: 160px
                text-align: center
                margin: 0px
                width: 100%
                color: #373636
                @media screen and (max-width: 1200px)
                    font-size: 2rem
                    letter-spacing: 0.055em
            span
                font-size: 1.4rem

.content-wrapper-content-offert
    padding: 0px
    border-radius: 0px
    display: flex
    background-color: #fff
    flex-basis: 50%
    height: auto
    background: #FFFFFF
    border-radius: 30px
    padding: 15px 0px 15px 0px
    z-index: 999
    margin-top: -100px
    margin-bottom: 50px
    flex-wrap: wrap
    box-shadow: 0px 0px 20px 3px rgba(1,1,1,0.2)
    @media screen and (max-width: 1200px)
        flex-basis: 100%
        margin-top: -50px
        border-radius: 0

.offert
    &-container
        position: relative
        display: flex
        flex-direction: row
        flex-wrap: wrap
        width: 100vw
        justify-content: center
    &-header
        display: flex
        flex-direction: row
        flex-wrap: wrap
        position: relative
        height: 540px
        flex-basis: 100%
        justify-content: center
        align-items: center
        color: #FFFFFF
        &-h1
            flex-basis: 100%
            text-align: center
            font-size: 6rem
            margin-bottom: 0
        &-p
            flex-basis: 100%
            text-align: center
            font-size: 2.34rem
        &-svg
            width: 90%
            transform: scale(1.3)
            position: absolute
            left: 0
            top: 0
            z-index: -9
            svg
                overflow: visible
        &-content
            margin-top: 50px
    &-content
        flex-basis: 80%
        position: relative
        height: auto
        display: flex
        flex-direction: row
        flex-wrap: wrap
        justify-content: center
        align-items: center
        margin-top: -140px
        @media screen and (max-width: 1400px)
            flex-basis: 80%

.card
    height: 504.69px
    flex-basis: 20%
    min-width: 240px
    background: #FFFFFF
    box-shadow: 0px 0px 124px rgba(0, 0, 0, 0.17)
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
    align-content: flex-start
    position: relative
    @media screen and (max-width: 1200px)
        flex-basis: 100%fb
    &-svg
        height: 120px
        // flex-basis: 100%
        // margin-left: calc(50% - 47px)
        margin-top: 20px
    &-p-from
        flex-basis: 100%
        font-size: 1.2rem
        text-align: center
        margin-top: 0px
        color: rgba(0, 0, 0, .63)
        font-weight: 500
    &-price
        font-size: 3rem
        font-family: "Montserrat"
        margin: 0
        flex-basis: 100%
        text-align: center
        span
            font-size: 2rem
            font-weight: 400
            margin-left: 8px
    &-list
        text-align: center
        flex-basis: 100%
        list-style: none
        padding: 0
        display: flex
        flex-direction: row
        flex-wrap: wrap
        display: flex
        flex-basis: 80%
        @media (max-width: 1500px)
            flex-basis: 90%
        li
            line-height: 30px
            width: 100%
            @media screen and (max-width: 1650px)
                padding: 0px
            @media screen and (max-width: 800px)
                padding-left: 20px
                text-align: left

        &-svg
            width: 30px
            height: 20px
            padding-top: 8px
    &-btn
        border: 1px solid #6B6B6B
        box-sizing: border-box
        border-radius: 5px
        flex-basis: 181px
        text-align: center
        font-weight: 500
        margin-top: 25px
        cursor: pointer
    &-bestseller
        width: calc(70%)
        height: 25px
        background: linear-gradient(89.81deg, #58B6EB 0.7%, #3E98DA 87.73%)
        border-radius: 21px
        position: absolute
        top: -14.5px
        p
            margin: 0px
            font-family: Poppins
            font-style: normal
            font-weight: bold
            font-size: 15px
            line-height: 25px
            align-items: center
            text-align: center
            letter-spacing: 0.11em
            color: #fff

.table
    width: 100%
    border-collapse: collapse
    margin-bottom: 15px
    th
        color: #77C1EB
        font-weight: 400
        height: 50px
        text-align: left

        padding: 8px
    td
        // border: 1px solid #ddd
        border-right: 1px solid #ddd
        padding: 8px
        color: rgba(0, 0, 0, 0.8)
        font-weight: 500
    tr
        border-bottom: 1px solid #ddd
    td:nth-child(2)
        font-weight: 400
        font-family: 'Roboto'
    td:nth-child(3)
        border-right: none
        font-weight: 400
        font-family: 'Roboto'
        // background-color: #f2f2f2            
    &-wrapper
        margin-top: 100px
        flex-basis: 90%
        display: flex
        box-shadow: 0px 0px 71px rgba(0, 0, 0, 0.08)
        height: auto
        min-height: 210px
        background: linear-gradient(89.81deg, rgba(36, 57, 89, 0.92) 5.91%, rgba(26, 154, 226, 0.58) 87.73%)
        margin-bottom: 100px
        padding-bottom: 10px
        border-radius: 20px
        @media screen and (max-width: 1200px)
            border-radius: none
    &-content
        position: relative
        width: 100%
        flex-basis: 100%
        height: auto
        min-height: 190px
        background-color: #fff
        border-radius: 17px
        padding: 5px
        z-index: 999
        display: flex
        flex-direction: row
        flex-wrap: wrap
        justify-content: center
        align-content: flex-start
    &-categories
        display: flex
        flex-direction: row
        flex-wrap: wrap
        flex-basis: 100%
        height: 60px
        &-line
            width: 20%
            position: absolute
            top: 60px
            left: 0px
            height: 5px
            background: linear-gradient(89.81deg, rgba(36, 57, 89, 0.92) 5.91%, rgba(26, 154, 226, 0.58) 87.73%)
            transition: .2s ease-in-out
    &-category
        flex-basis: 20%
        text-align: center
        border-bottom: 1px solid #eee
        p
            cursor: pointer
