@tailwind base;
@tailwind components;
@tailwind utilities;
body::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #5aa6fe;
}

body::-webkit-scrollbar {
  width: 8px;
  background-color: #5aa6fe;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dd2e44;
}
