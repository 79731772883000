.footer-wrapper
    width: 100%
    // height: 300px
    // display: flex
    // flex-wrap: wrap
    // align-items: flex-end
    overflow-y: hidden
    font-family: 'Roboto', sans-serif !important
    .footer-top
        width: 100%
        height: 100%
        position: relative
        #footer-svg1
            position: relative
            image
                width: 100%
        #footer-svg2
            position: absolute
            top: -3px
            left: 0
        .footer-top-content-wrapper
            width: 100%
            height: 100%
            // background: red
            position: absolute
            top: 0
            display: flex
            justify-content: center
            align-items: flex-end
            .footer-top-content
                width: 70%
                height: 60%
                // background: blue
                display: flex
                justify-content: space-between
                align-items: center
                flex-flow: row wrap
                .footer-top-logo-wrapper
                    width: 25%
                    height: 80%
                    // background: green
                    text-align: center
                    span
                        color: #fff
                        font-size: 2rem
                        font-weight: 600
                        position: relative
                        font-family: 'Roboto', sans-serif !important
                        &::after
                            content: ''
                            width: 30%
                            height: 5px
                            background: #fff
                            position: absolute
                            bottom: -7px
                            left: 0
                            
                .footer-top-section-wrapper
                    width: 25%
                    height: 80%
                    // background: red
                    .footer-top-section-header
                        color: #fff
                        font-family: 'Roboto', sans-serif !important
                        font-size: 1.2rem
                        font-weight: 700
                    .footer-top-section-item
                        color: #fff
                        font-family: 'Roboto', sans-serif !important
                        font-weight: 300
                        a
                            color: #fff
                            font-family: 'Roboto', sans-serif !important
                            font-weight: 300

    .footer-bottom
        width: 100%
        height: 51px
        background: #fff
        display: flex
        justify-content: center
        align-items: center
        flex-wrap: wrap
        .footer-bottom-left
            height: 100%
            width: 50%
            p
                margin-left: 20px
                color: rgba(42, 63, 94, 0.71)
                font-size: 1rem
                font-weight: 700
                font-family: 'Roboto', sans-serif !important
        .footer-bottom-right
            width: 50%
            height: 100%
            display: flex
            justify-content: flex-end
            align-items: center
            flex-wrap: wrap
            .footer-bottom-right-icons
                margin-right: 20px
                height: 100%
                display: flex
                justify-content: center
                align-items: center
                flex-wrap: wrap
                .footer-bottom-right-icon
                    margin: 0 5px
                    display: flex
                    justify-content: center
                    align-items: center
                    flex-wrap: wrap
@media screen and (max-width: 1200px)
    .footer-mobile-wrapper
        position: relative
        overflow: hidden
        .footer-mobile-background
            z-index: 10
            width: 100%
            height: 100vh
            @media screen and (min-width: 800px)
                height: 50vh
        .footer-mobile-content-wrapper
            width: 100%
            position: absolute
            top: 20%
            z-index: 100
            .footer-mobile-content
                margin: 20px 60px
                display: flex
                flex-wrap: wrap
                height: 100%
                &-header-wrapper
                    width: 100%
                    height: 100px
                    span
                        font-size: 2.2rem
                        color: #fff
                        font-weight: 600
                        font-family: 'Roboto'
                        position: relative
                        &::after
                            content: ''
                            width: 30%
                            height: 5px
                            background: #fff
                            position: absolute
                            bottom: -10px
                            left: 0
                .footer-mobile-sections
                    display: flex
                    align-items: center
                    flex-wrap: wrap
                    width: 100%
                    height: calc(100% - 100px)
                    .footer-mobile-section
                        color: #fff
                        width: 100%
                        @media screen and (min-width: 768px)
                            width: 33%
                        &-title
                            font-size: 1.3rem
                            font-weight: 600
                            font-family: 'Roboto'
                        &-item
                            font-weight: 300
                            font-family: 'Roboto'
                            a
                                color: #fff
                                font-family: 'Roboto', sans-serif !important
                                font-weight: 300
        .footer-mobile-bottom
            display: flex
            flex-wrap: wrap
            align-items: center
            position: relative
            margin-bottom: 20px
            height: 20px
            &::after
                content: ''
                width: 100%
                height: 10px
                background: #E1475B
                position: absolute
                bottom: -25px
            .footer-mobile-bottom-content
                display: flex
                flex-wrap: wrap
                align-items: center
                width: 100%
                height: 100%
                .footer-mobile-bottom-copyright
                    font-size: .75rem
                    font-family: 'Roboto'
                    font-weight: 700
                    color: #4271A8
                    position: absolute
                    left: 10px
                    top: 50%
                .footer-mobile-bottom-icons
                    width: 20%
                    display: flex
                    flex-wrap: wrap
                    justify-content: center
                    position: absolute
                    right: 0
                    top: 25%
                    .footer-mobile-bottom-icon
                        width: 30%

                            

