@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')
@import './_variables.sass' 
*, *::before, *::after
    font-family: 'Poppins', sans-serif
    box-sizing: border-box
html, body
    margin: 0
    padding: 0
    overflow-x: hidden

::-moz-selection 
  color: #fff
  background: $cromed_blue


::selection 
  color: #fff
  background: $cromed_blue
