@import '../../global-styles/_variables.sass' 
  
.blog-wrapper 
  width: 100vw
  display: flex
  justify-content: center
  position: relative
  overflow-y: scroll

  // &::-webkit-scrollbar
  //   width: 8px
  //   background-color: #5aa6fe
  // &::-webkit-scrollbar-thumb 
  //   border-radius: 10px
  //   background-color: #dd2e44
  // &::-webkit-scrollbar-track 
  //   border-radius: 10px
  //   background-color: #5aa6fe




.main-wrapper
  margin-top: 100px
  display: flex
  flex-direction: column
  flex-wrap: wrap
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  padding: 0 0 0 0
  background-color: #ffffff
  position: relative
  > h1
    color: #111
    text-align: center
    font-size: 3rem


.lastest-article-wrapper
  display: grid
  padding: 50px
  width: 85vw
  grid-template-columns: 1fr 1fr 
  grid-template-rows: 1fr 1fr 1fr
  grid-gap: 10px

.lastest-article
  position: relative
  border: 1px solid rgba(1, 1, 12, 0.7)
  padding: 5px
  border-radius: 10px
  width: 100%
  height: 100%
  background-color: #ffffff
  grid-column-start: 1
  grid-row-start: 1
  grid-row-end: 3
  img
    position: relative
  h2
    padding: 6px
    padding-left: 10px
    margin-bottom: 5px
    margin-top: 0px
  p.desc
    padding: 6px
    padding-left: 10px
    font-size: 0.8rem
    color: #111
    line-height: 1.5
    margin: 0
#lastest-article-image
  width: 100%
  border-radius: 10px
#bandge-lastest
  position: absolute
  top: 5px
  left: 5px
  width: fit-content
  color: #ffffff
  background-color: #24679a99
  border-bottom-right-radius: 10px
  border-top-left-radius: 10px
  padding: 10px
  font-size: 1.1rem
  text-align: center
  z-index: 2
#background-image-article-opacity-color
  position: absolute
  top: 0
  left: 0
  width: 100%
  border-radius: 10px
  height: calc( 98% - 2px )
  display: none
  background-color: rgba(36, 103, 154, 0.3)
  z-index: 1

#article-date-p
  font-size: 0.67rem

#blog_avatar
  padding-left: 0px
  grid-column-start: 1
  grid-column-end: 1
  grid-row-start: 1
  grid-row-end: 3
  padding-top: 5px
  
#author
  display: flex
  flex-direction: row
  justify-content: center
  align-items: flex-start
  justify-items: flex-start
  padding: 10px
  padding-left: 5px
  height: min-content
  width: 100%
  > .author_name
    flex-basis: 20%
    grid-column-start: 2
    grid-column-end: 3
    grid-row-start: 1
    grid-row-end: 1
    font-size: 0.8rem
    font-weight: 500
    color: #111
    margin: 0
    padding-left: 7px
    align-self: center
    line-height: 1.5
  > .see-more
    flex-basis: 80%
    align-self: flex-end
    > button
      float: right
      border: none
      background-color: #24679a
      color: #ffffff
      font-size: 0.9rem
      padding:7.5px 15px
      margin-bottom: 10px
      margin-right: 10px
.blog_category
  margin: 0
  font-size: 0.8rem
  margin-left: 10px
  padding: 6px
  background-color: #24679a
  width: min-content
  border-radius: 8px
  color: #fff


.lastest-item
  margin-left: 10px
  height: 200px
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  width: 100%
  &-image
    flex-basis:  50%
    width: 100%
    height: 100%
    display: block
    &:nth-child(1)
      border-radius: 10px 0 0 10px
      
  &-content
    flex-basis:  50%
    > h1
      font-size: 0.8rem
      margin: 0
      padding: 0
      padding-left: 10px
      margin-bottom: 5px
      margin-top: 0px