.employees 
    margin-top: 100px
    width: 100%
    height: auto
    &-header
        display: grid
        place-items: center
        h1
            margin-top: 100px
            font-size: 25px
            color: #333
            font-weight: 500
            position: relative
            &::after
                content: ''
                height: 3px
                width: 100px
                position: absolute
                bottom: -10px
                left: 0
                background: #eee
    &-container
        width: 100%
        height: auto
        margin-top: 100px
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .employee
            width: 70%
            height: auto
            margin: 50px 200px
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            @media screen and (max-width: 1200px)
                margin: 50px auto
                text-align: center
            &-img
                width: 150px
                height: auto
                border-radius: 50%
                img
                    width: 150px
                    height: auto
                    border-radius: 3%
            &-name
                font-size: 20px
                font-weight: 600
            &-desc
                font-size: 15px
                color: #222

